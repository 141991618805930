<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import Section from '$lib/Components/Layout/Section/Section.svelte';

	import Ratings from './rating_meter.svelte';
	import reviewsJSON from './reviews.json';
	import reviewsLogo from './reviewsio-logo.svg';

	//import { spring } from 'svelte/motion';

	export let title = 'Customer Reviews';
	export let backgroundColor = 'grey';
	export let layout = 'horizontal'; //False will give us a stacked layout
	export let reviews = reviewsJSON;

	let innerWidth;
	// TODO: move this explicit width declaration to instead pull from global css var(--site-width)
	let mobileBreakPoint = '64rem';
	let reviewsFiltered = reviews?.reviews;

	let upperBounds = reviewsFiltered?.length;
	let showArrows = layout == 'horizontal';

	// keep track of item scroll
	let scrollIndex = 1;

	//Reworked bounds checking. Figure out how to loop around to the first review.
	function boundsCheck(scrollIndex) {
		//If we try going left at the leftmost point we're looping back around.
		if (scrollIndex < 1) {
			scrollIndex = upperBounds;
		}

		//If we exceed the upper bound then we'll reset.
		if (scrollIndex > upperBounds) {
			scrollIndex = 1;
		}
		return scrollIndex;
	}

	let scroll = (offset) => {
		let oneRemInPx = getComputedStyle(document.documentElement).fontSize;
		let mobileWidth = parseFloat(oneRemInPx) * parseFloat(mobileBreakPoint);
		if (innerWidth > mobileWidth) offset = offset * 2;
		scrollIndex = scrollIndex += offset;
		scrollIndex = boundsCheck(scrollIndex);
		let section = document.getElementById(scrollIndex);
		section.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'nearest',
		});
	};
</script>

<svelte:window bind:innerWidth />
{#if reviewsFiltered?.length > 0}
	<Section {backgroundColor} {title}>
		<div class="reviews-container">
			{#if layout == 'horizontal'}
				<div class="button-container">
					<button
						aria-label="previous"
						class="arrow left"
						class:showArrows
						on:click={() => scroll(-1)}
					/>
				</div>
			{/if}
			<div class="middle">
				<div class="review-bubble">
					<!--The bubble and reviews are going to be the middle column and the arrows are 
			going to be the outer columns.-->
					<div class="rectangle bg-indow-white">
						<!-- TODO: when screen is small enough to wrap, items should still center properly -->
						<div class="rectangle-box left-rect">
							<div class="rating flex-wrap !text-indow-grey-dark">
								<span> Excellent </span>
								<span>
									<Ratings
										stars="5"
										rating={reviews?.stats?.company?.average_rating}
									/>
								</span>
							</div>
							<p class="review">
								<span class="!text-indow-grey-dark">
									{reviews?.stats?.company?.average_rating}
									<span class="!text-indow-grey">Average</span>
								</span>
								<span class="!text-indow-grey-dark">
									{reviews?.stats?.company?.review_count}
									<span class="!text-indow-grey">Reviews</span>
								</span>
							</p>
						</div>

						<a
							href="https://www.reviews.io/company-reviews/store/indow1?utm_source=indow1&utm_medium=widget&utm_campaign=carousel"
							target="_blank"
							rel="noreferrer"
						>
							<Image
								src={reviewsLogo}
								alt="Reviews.io logo"
								class="max-w-40"
								width="300"
								height="48"
								loading="lazy"
							/>
						</a>
					</div>
					<div class="triangle" />
				</div>

				<!--If there isn't JavaScript present then add the class for the overflow scrollbar.-->
				<div
					class="reviews"
					class:scroll-x={showArrows == false && layout == 'horizontal'}
					class:scroll-y={layout == 'vertical'}
				>
					<div
						class:review-container={layout == 'horizontal'}
						class:review-container-vertical={layout == 'vertical'}
					>
						{#each reviewsFiltered as review, i}
							<!--This ID property will allow our scroll functions to work.-->
							<div
								class:review-item={layout == 'horizontal'}
								class:review-item-vertical={layout == 'vertical'}
								id={i + 1}
							>
								<div class="inner">
									<span class="name-stars flex flex-wrap gap-x-4">
										<p
											class="name mb-0"
											class:w-auto={layout == 'vertical'}
											class:!text-indow-white={backgroundColor == 'grey'}
										>
											{review?.author?.name}
										</p>

										<span class="stars">
											<Ratings rating={review?.rating} stars="5" />
										</span>
									</span>

									<p
										class="text"
										time_ago
										class:!text-indow-white={backgroundColor == 'grey'}
									>
										{review?.comments}
									</p>
									<p class="timestamp">
										Posted {review?.time_ago}
									</p>
								</div>
							</div>
						{/each}
					</div>
				</div>
			</div>
			{#if layout == 'horizontal'}
				<div class="button-container">
					<button
						aria-label="next"
						class="arrow right"
						class:showArrows
						on:click={() => scroll(1)}
					/>
				</div>
			{/if}
		</div>
	</Section>
{/if}

<style>
	.name {
		font-weight: bold;
	}

	.name,
	.text {
		color: black;
		font-size: 0.75rem;
	}

	.timestamp {
		font-size: 0.75rem;
	}

	.inner {
		display: grid;
		row-gap: 1rem;
		padding: 0.25rem;
	}

	.scroll-y {
		overflow-y: scroll;
		height: 30rem;
	}

	.reviews-container {
		display: grid;
		align-items: center;
		grid-template-columns: auto 1fr auto;
	}

	.middle {
		max-width: 100%;
		min-width: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
	}

	.rating {
		display: flex;
		align-items: baseline;
		font-size: 1.25rem;
		text-transform: uppercase;
		font-weight: normal;
		column-gap: 0.25rem;
		place-self: flex-start;
	}

	.review {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: flex-end;
		column-gap: 0.5rem;
	}

	/*Shapes to make the "bubble"*/
	.rectangle {
		width: 100%;
		border-radius: 0.5rem;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	.rectangle > * {
		margin: 1rem;
	}

	.review-bubble {
		display: flex;
		flex-direction: column;
	}

	.rectangle-box {
		display: flex;
		gap: 0.5rem;
		flex-direction: column;
		align-items: flex-end;
	}

	.triangle {
		margin: auto;
		margin-top: -1px;
		width: 0;
		height: 0;
		border-left: 0.75rem solid transparent;
		border-right: 0.75rem solid transparent;
		border-top: 1rem solid white;
	}

	/*Styling for the reviews container*/
	.reviews {
		margin: auto;
		display: flex;
		max-width: 100%;
		overflow-x: hidden;
		scroll-snap-type: x proximity;
	}

	.scroll-x {
		overflow-x: scroll;
	}

	/*This is the styling for the navigation arrows*/
	.arrow {
		background: none;
		box-sizing: content-box;
		display: inline-block;
		border-left: none;
		border-top: none;
		border-right: 0.45rem solid var(--indow-grey-light);
		border-bottom: 0.45rem solid var(--indow-grey-light);
		width: 1rem;
		height: 1rem;
		margin: 0.5rem;
		cursor: pointer;
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.5s;
		place-self: start;
		margin-block-start: 7rem;
	}
	.showArrows {
		opacity: 1;
		pointer-events: initial;
	}

	.arrow:hover {
		border-color: var(--indow-grey-light);
	}

	.left {
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}

	.right {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}

	/*Review styling*/
	.review-container {
		display: grid;
		grid-template-columns: repeat(100, 100%);
		gap: 0.4rem;
		width: 50%;
	}

	.review-container-vertical {
		display: grid;
		width: 100%;
		row-gap: 1.5rem;
		place-items: center;
		margin: 1rem 0;
	}

	.review-item {
		scroll-snap-align: start;
		display: flex;
		flex-direction: column;
		min-width: 1rem;
		width: 100%;
	}

	.review-item-vertical {
		width: 90%;
		background-color: var(--indow-white);
		border: 0.05rem solid white;
	}

	.review-item > .inner {
		margin: 0 0.5rem;
		height: 100%;
		display: grid;
		grid-template-rows: auto 1fr auto;
		row-gap: 0.5rem;
	}
	.name {
		display: flex;
		font-weight: bold;
		font-size: 0.825rem;
	}
	.name-stars {
		display: flex;
	}
	.text {
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
	}
	/* TODO: animate the expand, add collapse */
	.text:hover,
	.text:focus {
		-webkit-line-clamp: unset;
	}
	.text,
	.timestamp {
		font-size: 1rem;
		text-align: left;
		line-height: 1.25;
	}

	.timestamp {
		text-align: right;
	}

	@media screen and (max-width: 768px) {
		.review-container {
			width: 100%;
			gap: 0;
		}
	}
	@media screen and (max-width: 768px) {
		.rating {
			place-self: center;
		}
		.rectangle {
			justify-content: space-around;
		}
		.review {
			justify-content: center;
		}
	}
	.button-container {
		overflow: hidden;
	}
</style>
